import { For } from 'solid-js';
import {
	BreadcrumbItem,
	Breadcrumbs,
	Button,
	Errors,
	FieldDescription,
	Form,
	Heading,
	HiddenFields,
	Input,
	Label,
	Select,
	Option,
	TextField,
	Field,
} from '@troon/ui';
import { clientAction } from '@troon/api-client';
import { countryNames } from '@troon/countries';
import { Content } from '../../../../../components/main-content';
import type { RouteSectionProps } from '@solidjs/router';

export default function NewCustomer(props: RouteSectionProps) {
	return (
		<Content>
			<Breadcrumbs omitHome>
				<BreadcrumbItem href={`/facility/${props.params.facility}/customer`}>Customers</BreadcrumbItem>
				<BreadcrumbItem href={`/facility/${props.params.facility}/customer/new`}>New Member</BreadcrumbItem>
			</Breadcrumbs>

			<Heading as="h1">New Troon Rewards Member</Heading>

			<div class="flex max-w-4xl flex-col gap-4 rounded border border-neutral bg-white p-4 xl:p-8">
				<Form action={signupAction}>
					<HiddenFields data={{ facilityId: props.params.facility }} />
					<TextField name="email" required>
						<Label>Email address</Label>
						<Input autocomplete="off" inputMode="email" />
						<FieldDescription>
							The email address will be used both for logging into <code>troon.com</code> to manage their account, check
							Troon Rewards points, and book tee times.
						</FieldDescription>
					</TextField>

					<div class="flex w-full flex-wrap justify-stretch gap-8">
						<TextField name="firstName" class="shrink grow" required>
							<Label>First name</Label>
							<Input autocomplete="off" />
						</TextField>
						<TextField name="lastName" class="shrink grow" required>
							<Label>Last name</Label>
							<Input autocomplete="off" />
						</TextField>
					</div>

					<TextField name="zipcode" required>
						<Label>Postal code</Label>
						<Input />
						<FieldDescription>
							If you enter a valid postal code, no need to enter a city, state/region, or country. It will get
							automatically populated. If in a country without postal codes, please enter 00000.
						</FieldDescription>
					</TextField>

					<div class="flex w-full flex-wrap justify-stretch gap-8">
						<TextField name="city" class="shrink grow">
							<Label>City</Label>
							<Input />
						</TextField>

						<TextField name="state" class="shrink grow">
							<Label>State/Region</Label>
							<Input />
						</TextField>
					</div>

					<Field name="country">
						<Label>Country</Label>
						<Select>
							<Option selected />
							<For each={countryNames}>{(country) => <Option value={country}>{country}</Option>}</For>
						</Select>
					</Field>

					<Errors />

					<Button type="submit">Sign up</Button>
				</Form>
			</div>
		</Content>
	);
}

const signupAction = clientAction(
	'POST',
	'/v0/operator/facilities/{facilityId}/user/signup',
	(data) => ({
		params: { path: { facilityId: data.get('facilityId') as string } },
	}),
	{
		redirect: (data, input) => `/facility/${input.get('facilityId')}/customer/${data.rewardsId}`,
	},
);
